
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import RegisterTip from "./RegisterTip.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigCity",
  components: { RegisterTip },
  setup() {
    let data = reactive<any>({
      init: false,
      maps: new Map(),
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: { csid: 0 },
        sort: "-enabled",
      },
      tipDialogVisible: false,
      // baseURL: "http://localhost:5270/api/admin",
    });

    const searchChange = (params: any, done: any) => {
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetCityList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          data.data.data.forEach((element: any) => {
            if (element.csid == 0) element.hasChildren = true;
          });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetCityOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const treeLoad = (tree: any, treeNode: any, resolve: any) => {
      var cityquery = {
        limit: 99,
        page: 1,
        where: { csid: tree.id },
        sort: "standid",
      };
      data.maps.set(tree.id, { tree, treeNode, resolve });
      Api.Config.GetCityList(cityquery).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          resolve(res.Data.data);
        }
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      Api.Config.AddCity(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          data.maps.forEach((key: any) => {
            const { tree, treeNode, resolve } = data.maps.get(key);
            treeLoad(tree, treeNode, resolve);
          });
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Config.EditCity(form.id, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const CityRemove = (row: any) => {
      Api.Config.EditCity(row.id, row).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    const RegisterTipRef = ref<InstanceType<typeof RegisterTip>>();
    const SetTip = (row: any) => {
      data.tipDialogVisible = true;
      if (row.csid == 0) {
        RegisterTipRef.value?.searchChange(1, row.id, 0, row.cityname);
      } else {
        RegisterTipRef.value?.searchChange(2, row.id, 0, row.cityname);
      }
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      CityRemove,
      treeLoad,
      SetTip,
      RegisterTipRef,
    };
  },
});
