
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  name: "ConfigRegisterTime",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-OrderId",
      },
      keytype: 0,
      key: 0,
      title: "",
      hospitalId: 0,
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (
      keytype: number,
      key: number,
      hospitalId: number,
      title: string
    ) => {
      switch (keytype) {
        case 1:
          data.query.where = {
            csId: key,
          };
          break;
        case 2:
          data.query.where = {
            cityId: key,
          };
          break;
        case 3:
          data.query.where = {
            hospitalId: key,
          };
          break;
        case 4:
          data.query.where = {
            departmentId: key,
          };
          break;
        default:
          break;
      }
      data.hospitalId = hospitalId;
      data.keytype = keytype;
      data.key = key;
      data.title = title;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      console.log(data.query);

      getList();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetTipList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetTipListOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      form.createdAt = null;
      console.log(form);
      done();
      switch (data.keytype) {
        case 1:
          form.csId = data.key;
          form.cityId = 0;
          form.hospitalId = 0;
          form.departmentId = 0;
          break;
        case 2:
          form.csId = 0;
          form.cityId = data.key;
          form.hospitalId = 0;
          form.departmentId = 0;
          break;
        case 3:
          form.csId = 0;
          form.cityId = 0;
          form.hospitalId = data.key;
          form.departmentId = 0;
          break;
        case 4:
          form.csId = 0;
          form.cityId = 0;
          form.hospitalId = data.hospitalId;
          form.departmentId = data.key;
          break;
        default:
          break;
      }
      Api.Config.AddTip(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Config.EditTip(form.TipId, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowDel = (row: any, index: any, done: any) => {
      ElMessageBox.confirm("删除后无法恢复，确定要删除吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
      })
        .then(() => {
          row.Status = 0;
          Api.Config.EditTip(row.TipId, row).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("保存成功");
              done(row);
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          console.log("xx");
        });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (oldindex == newindex) return;
      const order = {
        key: data.data.data[newindex].TipId,
        steps: oldindex - newindex,
      };
      Api.Config.OrderTip(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      dialogVisible,
      changePage,
      rowSave,
      rowUpdate,
      rowDel,
      searchChange,
      sortableChange,
    };
  },
});
