import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avue_crud = _resolveComponent("avue-crud")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: _ctx.title,
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      (_ctx.init)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_avue_crud, {
              ref: "crud",
              data: _ctx.data.data,
              option: _ctx.option,
              page: _ctx.page,
              "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
              onRowSave: _ctx.rowSave,
              onOnLoad: _ctx.changePage,
              onRowUpdate: _ctx.rowUpdate,
              onRowDel: _ctx.rowDel,
              onSearchChange: _ctx.searchChange,
              onSortableChange: _ctx.sortableChange
            }, null, 8, ["data", "option", "page", "onRowSave", "onOnLoad", "onRowUpdate", "onRowDel", "onSearchChange", "onSortableChange"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "modelValue"]))
}